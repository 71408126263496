<template>
  <b-modal
    title="Crear permiso"
    v-model="show"
    size="xl"
    no-close-on-backdrop
    scrollable
  >
    <b-row>
      <b-col md="6">
        <div class="form-group">
          <label>Permiso</label>
          <input
            v-model="form.name"
            type="text"
            class="form-control form-control-sm"
            placeholder="Component"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div class="form-group">
          <label>Nombre amigable</label>
          <input
            @keyup.enter="create()"
            v-model="form.guard_name"
            type="text"
            class="form-control form-control-sm"
            placeholder="Referencia"
          />
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="form-group">
        <button class="btn btn-sm btn-circle" @click="() => (show = false)">
          <i class="la la-reply"></i> Cancelar
        </button>
        <button class="btn btn-sm btn-success" @click="create()">
          <i class="la la-save"></i> {{ loading ? "Cargando..." : "Guardar" }}
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import toastr from "toastr";
export default {
  data() {
    return {
      show: false,
      loading: false,
      form: {
        name: null,
        guard_name: null,
      },
    };
  },
  methods: {
    reset() {
      this.form = {
        name: null,
        guard_name: null,
      };
    },
    create() {
      if (this.form.name && this.form.guard_name) {
        this.loading = true;
        axios
          .post("permissions", this.form)
          .then((res) => {
            if (res.data.message == "success") {
              this.show = false;
              this.loading = false;
              this.$emit("loadPermission", true);
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      } else {
        toastr.info(`Todos los campos son obligatorios`);
      }
      //code
    },
  },
};
</script>
