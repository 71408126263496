<template>
  <b-row class="mt-5">
    <b-col>
      <b-card title="Permisos">
        <button
          class="btn btn-sm btn-circle btn-outline-primary mb-2 mr-3"
          @click="loadPermission()"
        >
          <i class="flaticon-refresh"></i>
        </button>
        <button
          class="btn btn-sm btn-circle btn-primary mb-2"
          @click="addPermission()"
          v-if="can('create-permission')"
        >
          <i class="flaticon-safe-shield-protection text-white"></i> Agregar
        </button>
        <transition name="fade">
          <b-row v-if="items.length">
            <b-col class="dx-viewport">
              <DxDataGrid
                id="permisos"
                :data-source="items"
                :show-borders="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="true"
                :columnAutoWidth="true"
                :allow-column-resizing="true"
              >
                <DxSearchPanel
                  :visible="true"
                  :width="240"
                  placeholder="Buscar..."
                />
                <DxPaging :page-size="15" />
                <DxPager
                  :visible="true"
                  infoText="Página {0} de {1} ({2} registros)"
                  :allowed-page-sizes="pageSizes"
                  display-mode="full"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
                <DxHeaderFilter :visible="true" />
                <DxFilterRow :visible="true">
                  <DxOperationDescriptions
                    equal="Igual a"
                    notEqual="Diferente de"
                    startsWith="Empieza con"
                    endsWith="Termina con"
                    contains="Contiene"
                    between="Entre"
                    notContains="No contiene"
                    lessThanOrEqual="Menor o igual"
                    greaterThanOrEqual="Mayor o igual"
                    lessThan="Menor que"
                    greaterThan="Mayor que"
                  />
                </DxFilterRow>
                <DxColumn data-field="name" caption="Permiso" />
                <DxColumn data-field="guard_name" caption="Alias" />
                <DxColumn
                  data-field="created_at"
                  caption="Fecha creación"
                  data-type="date"
                  format="dd/MM/yyyy, HH:mm:ss"
                />
                <DxColumn caption="" width="150" cell-template="cellTemplate" />
                <template #cellTemplate="{ data: cellData }">
                  <div class="text-center">
                    <router-link
                      :to="{
                        name: 'permissionsUpdate',
                        params: { id: cellData.data.id },
                      }"
                      v-if="can('update-permission')"
                      class="btn btn-sm btn-circle action"
                    >
                      <i class="flaticon2-pen text-success"></i>
                    </router-link>
                    <button
                      v-if="can('delete-permission')"
                      class="btn btn-sm btn-circle action"
                      @click="deletePermission(cellData.data.id)"
                    >
                      <i class="flaticon2-rubbish-bin text-danger"></i>
                    </button>
                  </div>
                </template>
              </DxDataGrid>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div v-if="!loading">
                <div class="text-center">
                  <img src="assets/img/empty.png" alt="Empty" />
                  <p class="text-muted">No hay información para mostrar</p>
                </div>
              </div>
              <div class="text-center" v-else>
                <img src="assets/img/loader.gif" alt="Loading..." />
              </div>
            </b-col>
          </b-row>
        </transition>
      </b-card>
      <CreatePermission
        ref="CP"
        @loadPermission="loadPermission()"
        v-if="can('create-permission')"
      />
    </b-col>
  </b-row>
</template>
<style>
.action:hover i {
  opacity: 0.7 !important;
}
#permisos {
  max-height: 700px !important;
}
</style>
<script>
import axios from "axios";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxOperationDescriptions,
} from "devextreme-vue/data-grid";
import CreatePermission from "./CreatePermission.vue";
import can from "@/permission";
export default {
  name: "permission-component",
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxOperationDescriptions,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    CreatePermission,
  },
  data() {
    return {
      items: [],
      loading: false,
      pageSizes: [5, 10, 15, 30, 50],
      texts: {
        editRow: "Editar",
        saveRowChanges: "Guardar",
        saveAllChanges: "Guardar cambios",
        deleteRow: "Eliminar",
        cancelRowChanges: "Cancelar",
        confirmDeleteTitle: "Información",
        confirmDeleteMessage: "¿Realmente desea eliminar el registro?",
        cancelAllChanges: "Descartar cambios",
      },
    };
  },
  mounted() {
    this.loadPermission();
  },
  methods: {
    can: can,
    addPermission() {
      // code
      const CP = this.$refs.CP;

      CP.show = true;
      CP.reset();
    },
    loadPermission() {
      this.loading = true;
      this.items = [];
      axios
        .get("permissions")
        .then((res) => {
          this.items = res.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    deletePermission(id) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar el permiso?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/permissions/${id}`)
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    "El registro ha sido eliminado correctamente",
                    "success"
                  );
                  this.loadUser();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
  },
};
</script>
